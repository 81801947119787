<template>
  <div>
    <b-row class="match-height mt-3">
      <b-col
        xs="5"
        md="5"
        lg="5"
        class="d-flex justify-content-center align-items-center"
      >
        <img
          style="cursor: pointer; width: 50%;"
          :src="require('@/assets/images/icons/plus.svg')"
          @click="showModal = true"
        >
      </b-col>
      <b-col
        xs="6"
        md="6"
        lg="6"
      >
        <ecommerce-goal-overview :data="goalOverview" />
      </b-col>
    </b-row>
    <b-card>
      <b-row>
        <b-col
          cols="12"
          md="12"
          class="mb-2 mt-md-0"
        >
          <b-row>
            <b-col
              cols="4"
              md="4"
              class="mb-2 mt-md-0"
            >
              <flat-pickr
                v-model="rangeDate"
                placeholder="Date"
                class="form-control mr-1"
                :config="{ mode: 'range'}"
                @input="setQuery"
              />
            </b-col>
            <b-col
              cols="4"
              class="mb-2 mt-md-0"
            >
              <v-select
                v-model="shop"
                placeholder="Shop"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="shopOptions"
                @input="setQuery"
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col
          cols="12"
          md="12"
          class="mb-2 mt-md-0"
        >
          <b-row>
            <b-col
              xs="4"
              md="4"
              lg="4"
            >
              <statistic-card-vertical
                color="success"
                icon="BoxIcon"
                :statistic="`${counterNumber}`"
                statistic-title="Passage client"
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col
          xs="12"
          md="12"
          lg="12"
        >
          <b-table-lite
            responsive
            :items="count"
            :fields="[{key: 'firstName', label: 'Nom'}, '_createdAt', 'firstVisit', 'source', 'other', 'shop']"
          >
            <template #cell(firstVisit)="data">
              {{ data.item.firstVisit ? 'Oui' : 'Non' }}
            </template>
            <template #cell(_createdAt)="data">
              {{ $moment(data.item._createdAt).format('HH:mm - DD/MM/YYYY') }}
            </template>
            <template #cell(shop)="data">
              <b-badge
                pill
                :variant="`light-${data.item.shop === 'PARIS' ? 'primary' : data.item.shop === 'TOULOUSE' ? 'warning' : 'success'}`"
              >
                {{ data.item.shop }}
              </b-badge>
            </template>
          </b-table-lite>
        </b-col>
      </b-row>
    </b-card>
    <b-modal
      v-model="showModal"
      centered
      size="lg"
      :hide-footer="true"
      title="Entrez les informations clients"
    >
      <b-row>
        <b-col
          xs="6"
          md="6"
          lg="6"
          classs="mt-2"
        >
          <span>Nom :</span>
          <b-form-input
            v-model="form.firstName"
            class="mb-1"
          />
        </b-col>
        <b-col
          xs="6"
          md="6"
          lg="6"
          classs="mt-2"
        >
          <span>Prénom :</span>
          <b-form-input
            v-model="form.lastName"
            class="mb-1"
          />
        </b-col>
        <b-col
          xs="6"
          md="6"
          lg="6"
        >
          <span>Sexe:</span>
          <v-select
            v-model="form.gender"
            placeholder="Sexe"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="Sexe"
            :options="genderOptions"
            class="invoice-filter-select mt-1"
          />
        </b-col>
        <b-col
          xs="6"
          md="6"
          lg="6"
        >
          <span>Type :</span>
          <v-select
            v-model="form.type"
            placeholder="Type"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="typeOptions"
            class="invoice-filter-select mt-1"
          />
        </b-col>
        <b-col
          xs="6"
          md="6"
          lg="6"
          class="mt-1"
        >
          <span>Age:</span>
          <v-select
            v-model="form.age"
            placeholder="Age"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="ageOptions"
            class="invoice-filter-select mt-1"
          />
        </b-col>
        <b-col
          xs="6"
          md="6"
          lg="6"
          class="mt-1"
        >
          <span>Source :</span>
          <v-select
            v-model="form.source"
            placeholder="Source"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="sourceOptions"
            class="invoice-filter-select mt-1"
          />
        </b-col>
        <b-col
          xs="12"
          md="12"
          lg="12"
          class="d-flex my-1"
        >
          <span>Première visite :</span>
          <b-form-checkbox
            v-model="form.firstVisit"
            class="ml-1"
          />
        </b-col>
        <b-col
          xs="12"
          md="12"
          lg="12"
          classs="mt-2"
        >
          <span>Notes :</span>
          <b-form-textarea
            v-model="form.other"
            class="mb-1"
          />
        </b-col>
        <b-col
          xs="12"
          md="12"
          lg="12"
          class="d-flex justify-content-end mt-2"
        >
          <b-button
            variant="primary"
            @click="change('add')"
          >
            <span class="align-middle">Enregistrer</span>
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BModal,
  BButton,
  BFormCheckbox,
  BFormTextarea,
  BFormInput,
  BTableLite,
  BBadge,
  BCard,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import EcommerceGoalOverview from './ecommerce/EcommerceGoalOverview.vue'

export default {
  components: {
    BRow,
    BCol,
    BModal,
    BButton,
    vSelect,
    BFormCheckbox,
    BFormTextarea,
    EcommerceGoalOverview,
    BFormInput,
    BTableLite,
    BCard,
    flatPickr,
    StatisticCardVertical,
    BBadge,
  },
  data() {
    return {
      goalOverview: {},
      showModal: false,
      genderOptions: ['Femme', 'Homme', 'Je ne sais pas'],
      typeOptions: ['Seul', 'Couple', 'Famille'],
      ageOptions: ['- de 24 ans', 'de 25 à 34 ans', 'de 35 à 44 ans', 'de 45 à 54 ans', 'de 55 à 64 ans', '+ de 65 ans'],
      sourceOptions: ['Passage rue', 'Site', 'Influenceurs', 'Presse', 'Métro', 'TV', 'Affichage', 'Autres'],
      count: [],
      counterNumber: 0,
      shopOptions: ['PARIS', 'LYON', 'TOULOUSE'],
      rangeDate: null,
      form: {
        gender: '',
        type: '',
        age: '',
        source: '',
        firstName: '',
        lastName: '',
        firstVisit: true,
      },
    }
  },
  async created() {
    try {
      const { counters } = await this.$http.get('/admin/boutique/counter')
      const count = await this.$http.get('/admin/boutique/counter/list')
      this.goalOverview = counters
      this.count = count?.counters
      this.counterNumber = count?.number
      console.log(counters, count)
    } catch (err) {
      console.log(err)
    }
  },
  methods: {
    async setQuery() {
      try {
        let dates = null
        let filter = {}
        if (this.rangeDate && this.rangeDate.includes('to') && this.rangeDate.split(' to ')?.length === 2) {
          dates = this.rangeDate.split(' to ')
          filter = { _createdAt: { $gte: this.$moment(dates[0]).toDate().getTime(), $lte: this.$moment(dates[1]).toDate().getTime() } }
        }
        if (this.shop) {
          filter = { ...filter, shop: this.shop }
        }
        const count = await this.$http.get('/admin/boutique/counter/list', { params: { filter } })
        this.count = count?.counters
        this.counterNumber = count?.number
      } catch (err) {
        console.log(err)
      }
    },
    async change() {
      try {
        console.log('tete')
        await this.$http.post('/admin/boutique/counter', { form: this.form })
        this.showModal = false
      } catch (err) {
        console.log(err)
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
